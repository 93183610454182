<template>
  <SSPWA />
</template>

<script>
import SSPWA from './components/SSPWA.vue'

export default {
  name: 'App',
  components: {
    SSPWA
  }
}
</script>

<style>
html {
  width:  100% !important;
  height: 100% !important;
  overflow: hidden !important;
}
body {
  width:  100% !important;
  height: 100% !important;
  overflow: hidden !important;
  margin: 0;
}
#app {
  width:  100% !important;
  height:  100% !important;
  overflow: hidden !important;
}
</style>
