<template>
  <div v-if="showHome" id="home" class="page">
    <div class="page-title">
      <h1 ref="title">Technology Consulting Services</h1>
    </div>
    <div class="page-content" ref="content">
      <a class="asp-embed-link" href="https://express.adobe.com/page/giFdkKyNeyEqs/" target="_blank"><img src="https://express.adobe.com/page/giFdkKyNeyEqs/embed.jpg?buster=1664814227864" alt="Laeta Development" style="width:100%" border="0" /></a>
    </div>
    <div class="page-cta" ref="cta">
      <button class="background-animation" @click="view">View on GitHub</button>
      <button class="background-animation" @click="load">Learn More</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'home',
  data() {
    return {
      showHome: true
    }
  },
  methods: {
    // TODO: refactor this method to be dynamic and enlarge font as well
    overflow() {
      if (this.$refs.title.scrollHeight > this.$refs.title.clientHeight) {
        this.$refs.title.style.fontSize = "3.5vw";
        if (this.$refs.title.scrollHeight > this.$refs.title.clientHeight) {
          this.$refs.title.style.fontSize = "3vw";
          if (this.$refs.title.scrollHeight > this.$refs.title.clientHeight) {
            this.$refs.title.style.fontSize = "2vw";
            if (this.$refs.title.scrollHeight > this.$refs.title.clientHeight) {
              this.$refs.title.style.fontSize = "1vw";
            }
          }
        }
      }
      if (this.$refs.content.scrollHeight > this.$refs.content.clientHeight) {
        this.$refs.content.style.fontSize = ".8em";
        if (this.$refs.content.scrollHeight > this.$refs.content.clientHeight) {
          this.$refs.content.style.fontSize = ".7em";
          if (this.$refs.content.scrollHeight > this.$refs.content.clientHeight) {
            this.$refs.content.style.fontSize = ".6em";
            if (this.$refs.content.scrollHeight > this.$refs.content.clientHeight) {
              this.$refs.content.style.fontSize = ".5em";
            }
          }
        }
      }
    },
    view() {
      window.open('https://github.com/laetadevelopment','_blank');
    },
    load() {
      this.$emit("load", "learnMore");
    }
  },
  mounted() {
    this.overflow();
    window.addEventListener("resize", this.overflow);
    let expressScript = document.createElement('script')
    expressScript.setAttribute('src', 'https://express.adobe.com/page-embed.js')
    document.body.appendChild(expressScript)
  }
}
</script>

<style>
#home .page-content div {
  width: 100%;
}
</style>
