<template>
  <div v-if="showLearnMore" id="learnMore" class="page">
    <div class="page-title">
      <h1 ref="title">Learn More About Laeta Development</h1>
    </div>
    <div class="page-content" ref="content">
      <p>At Laeta Development we take pride in the success of our clients. For us, a good business deal is one where all parties are happy and satisfied once the job is done. Through constant communication, rigorous attention to detail, and countless years of experience in all areas of technology we aim to achieve incredible solutions that others deem impossible or not feasible. We never give up, nothing is impossible!</p>
    </div>
    <div class="page-cta" ref="cta">
      <button class="background-animation" @click="view">View on GitHub</button>
      <button class="background-animation" @click="load">Go Home</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'learnMore',
  data() {
    return {
      showLearnMore: true
    }
  },
  methods: {
    overflow() {
      if (this.$refs.title.scrollHeight > this.$refs.title.clientHeight) {
        this.$refs.title.style.fontSize = "3.5vw";
        if (this.$refs.title.scrollHeight > this.$refs.title.clientHeight) {
          this.$refs.title.style.fontSize = "3vw";
          if (this.$refs.title.scrollHeight > this.$refs.title.clientHeight) {
            this.$refs.title.style.fontSize = "2vw";
            if (this.$refs.title.scrollHeight > this.$refs.title.clientHeight) {
              this.$refs.title.style.fontSize = "1vw";
            }
          }
        }
      }
      if (this.$refs.content.scrollHeight > this.$refs.content.clientHeight) {
        this.$refs.content.style.fontSize = ".8em";
        if (this.$refs.content.scrollHeight > this.$refs.content.clientHeight) {
          this.$refs.content.style.fontSize = ".7em";
          if (this.$refs.content.scrollHeight > this.$refs.content.clientHeight) {
            this.$refs.content.style.fontSize = ".6em";
            if (this.$refs.content.scrollHeight > this.$refs.content.clientHeight) {
              this.$refs.content.style.fontSize = ".5em";
            }
          }
        }
      }
    },
    view() {
      window.open('https://github.com/laetadevelopment','_blank');
    },
    load() {
      this.$emit("load", "home");
    }
  },
  mounted() {
    this.overflow();
    window.addEventListener("resize", this.overflow);
  }
}
</script>
