<template>
  <div id="sspwa">
    <div id="viewable">
      <index />
    </div>
  </div>
</template>

<script>
import index from './sspwa/index.vue'

export default {
  name: 'SSPWA',
  components: {
    index
  }
}
</script>

<style scoped>
#sspwa {
  width: 100%;
  height: 100%;
}
#viewable {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
